<template>
  <v-main app :style="bgStyle">
    <div class="d-flex flex-column flex-grow-1 h-full">
      <v-container class="pt-6 px-4">
        <!-- If using vue-router -->

        <!-- <customizer /> -->
        <transition mode="out-in">
          <router-view />
        </transition>
      </v-container>
      <div class="flex-grow-1"></div>
      <app-footer></app-footer>
    </div>
  </v-main>
</template>
<script>
export default {
  data: () => ({
    imageUrl: null,
  }),
  computed: {
    bgStyle() {
      if (!this.imageUrl) return null;

      const color = this.$vuetify.theme.dark ? "#1e1e1e" : "#fff";
      return {
        "background-size": "100vw",
        backgroundImage: `radial-gradient(circle at 50% 50%, ${color} 0%, transparent 90vw),
        linear-gradient(
          to bottom,
          transparent 0%,
          ${color} min(500px, calc(100vw / (16 / 9)))
                    ),
                    url("${this.imageUrl}")`,
        "background-repeat": "no-repeat",
        "background-position": "top center",
      };
    },
  },
  methods: {
    setImgBg(url) {
      this.imageUrl = url;
    },
  },
  mounted() {
    this.$root.$on("setImgBg", this.setImgBg);
    this.$root.$on("setBg", this.setBg);
  },
  destroyed() {
    this.$root.$off("setImgBg", this.setImgBg);
    this.$root.$off("setBg", this.setBg);
  },
  components: {
    AppFooter: () => import("./Footer.vue"),
  },
};
</script>
